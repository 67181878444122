/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

html {
  background: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a:-webkit-any-link:active {
  color: none !important;
}

:root {
  --black: #17191f;
  --light-grey: rgba(23, 25, 31, 0.4);
  --grey: #808080;
  --blue: #69a3f2;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.black__button {
  cursor: pointer;
  border-radius: 8px;
  background: var(--black);
  color: #fff;
  width: fit-content;
  padding-right: 8px;
  padding-left: 8px;
  height: 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height:28px;
  border: none;
  //line-height: 20px;
}

.recap__container {
  background-color: #f7f7f7;
  width: 85.28%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding-bottom: 50px;
  @media (max-width: 1450px) {
    width: 84.28%;
  }
  @media (max-width: 1350px) {
    width: 83.28%;
  }
  @media (max-width: 1024px) {
    width: 79.28%;
  }
  @media (max-width: 850px) {
    width: 100%;
    position: relative;
  }
}

.login__container {
  width: 100%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  a {
    text-decoration-color: #808080;
  }
  .purple {
    position: absolute;
    left: 0;
    z-index: -1;
  }

  .blue {
    position: absolute;
    right: 0;
    top: -200px;
    z-index: -1;
  }

  .green {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #17191f;
    margin-bottom: 35px;
    @media(max-width:1450px){
      margin-bottom:15px;
    }
    @media(max-width:900px){
      margin-bottom:35px;
    }
    @media (max-width: 600px) {
      line-height: 30px;
    }
  }
}

.login__form {
  @media (max-width: 900px) {
    width: 60%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
}

form {
  display: flex;
  flex-direction: column;
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #818181;
    margin-bottom: 6px;
  }
  input {
    width: 296px;
    border: 1px solid #d3d3d3;
    color:#818181;
    border-radius: 5px;
    padding-left: 10px;
    height: 43px;
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
    @media(max-width:1400px){
      margin-bottom:10px;
    }
    @media (max-width: 900px) {
      width: fill-available;
      margin-bottom:20px;
    }
    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #a9a9a9;
      font-family: "Inter", sans-serif;
    }
    &:focus-visible {
      outline: none;
    }
  }

  .psw__field {
    position: relative;
    font-family: "Inter", sans-serif;
    width: 296px;
    @media (max-width: 900px) {
      width: fill-available;
    }
    .eye__vector {
      position: absolute;
      right: 10px;
      top: 30%;
    }
  }

  button {
    height: 43px;
    width: 296px;
    background: #17191f;
    border: 1px solid #17191f;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 3px;
    margin-bottom: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    @media (max-width: 900px) {
      width: fill-available;
    }
  }

  .form__error {
    color: #dc5a3a;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-top: -13px;
  }

  .psw__error {
    width: 296px;
    margin-bottom: 10px;
  }

  .field__error {
    border: 1px solid #dc5a3a !important;
  }

  .forgot-psw__link {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #818181;
    text-align: center;
    cursor: pointer;
  }
}

.psw-reset__notice {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #17191f;
  text-align: center;
}

.signin__notice {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #818181;
  text-align: center;
  margin-bottom: 25px;
}

.register__link {
  color: #808080;
  font-size: 12px;
  line-height: 15px;
  font-weight:normal;
  margin-top:8px;
}

.tos__title {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.tos__text {
  width: 296px;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 18px;
  color: #818181;
  height: 100px;
  overflow-y: scroll;
  margin-bottom: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 3px;
  @media(max-width:1400px){
    height:80px;
  }
  @media (max-width: 900px) {
    width: fill-available;
    height:100px;
  }
  a {
    color: var(--black);
    text-decoration: underline;
  }
}

.tos__flex {
  display: flex;
  width: 296px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 16px;
    color: #818181;
  }
  input {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

//404

.not-found__flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--black);

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 145px;
    margin-bottom: 10px;
  }
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 35px;
  }
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: var(--black);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 35px;
  }
  button{
    height: 35px;
    padding: 4px 16px;
  }
}

.login__title{
  text-align: center;
  @media(max-width:600px){
    padding-right:15px;
    padding-left:15px;
  }
}

.success-message {
  color: var(--black);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 35px;
  @media (min-width: 900px) {
    width: 296px;
  }
}

.password-reset__title{
  text-transform: none!important;
}
